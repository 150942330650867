<template>
  <section class="" style="">
    <div class="columns is-multiline">
      <!-- <div class="column action-column is-full"> -->
        <div class="column is-centered is-half">
          <b-field :label="'Repeat every ' + time + ' mins'">
            <b-slider v-model="time" size="is-medium" :step="5" :min="5" :max="60" ticks>
            </b-slider>
          </b-field>
        </div>
        <div class="column is-centered is-half">
          <b-field label="Announcement Channel">
            <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a channel to send the message!" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" v-model="channel" :getOptionLabel="option => option.name" label="Mention Channel">
              <template slot="option" slot-scope="option" style="width: 100%">
                <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                  <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                    <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                  </div>
                </b-tooltip>
              </template>
            </v-select>
          </b-field>
        </div>
        <div class="column is-centered is-half">
          <b-field label="Message" style="text-align: left">
            <b-input placeholder="Enter message here!" type="textarea" v-model="message" maxlength="300" minlength="2" expanded name="message" custom-class="input-default"></b-input>
          </b-field>
        </div>
        <div class="column is-centered is-half">
          <b-field label="Message Content PlaceHolders">
            <p>
              -<code style="background-color: var(--main-color-bg-2);">%guild:name%</code> = Guild/Server Name<br>-<code style="background-color: var(--main-color-bg-2);">%guild:id%</code> = Guild/Server id<br>-<code style="background-color: var(--main-color-bg-2);">%newline%</code> = New line OR (press shift enter)
            </p>
          </b-field>
        </div>
      <!-- </div> -->
    </div>
    <div class="columns is-centered is-multiline">
      <div class="column is-centered" style="padding: 1.5rem;">
        <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></vue-recaptcha>
        <b-button type="is-success" :loading="this.$parent.$parent.$parent.$parent.actionAdding" :disabled="(!time || time < 5 || time.length > 60) || (!message || message.length <= 5 || message.length > 300) || !channel || this.$parent.$parent.$parent.$parent.actionAdding" @click='submit'>Submit</b-button>
      </div>
    </div>
  </section>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: 'AutoMessage',
    props: ['guildData', 'disabled'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    components: {
      VueRecaptcha,
      vSelect
    },
    data () {
      return {
        time: 0,
        message: null,
        channel: null,
        SelectPlacement: 'bottom'
      }
    },
    methods: {
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      submit () {
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.$parent.$parent.$parent.$parent.actionAdding = true
        console.log(recaptchaToken, this)
        const snackbar = this.$buefy.snackbar.open({
          message: 'Adding Tag, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/action/add`, body: { type: 'automessage', time: Number(this.time), channel: this.channel, content: this.message.trim(), recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Auto Message Added!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$parent.$parent.$parent.$parent.getGuild(true)
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$refs.recaptcha.reset()
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.getGuild()
        })
      },
      onCaptchaExpired: function () {
        this.$parent.$parent.$parent.$parent.actionAdding = false
        this.$refs.recaptcha.reset()
      }
    }
  }
</script>

<style>
  .tabs a {
    color: white;
  }
  .tabs ul {
    border-bottom-color: darkgrey;
  }
  .tabs a:hover {
    border-bottom-color: #7957d5;
    color: #7957d5;
  }
</style>

<style scoped>
  /* .label {
    float: left;
  } */
  .prefixButton {
    background: var(--main-color-bg-2);
    color: red;
    border-color: var(--main-color-bg-3) !important;
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.25) !important;
    z-index: 1 !important;
  }
  .columns {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
</style>
