<template>
  <section class="" style="">
    <!-- Requires commandName, Tag Message | allows %user:ping% %user:text% %user:id% %guild:name% %guild:id% %newline% -->
    <div class="column is-centered is-half">
      <b-field label="Command Name" style="text-align: left">
        <p class="control prefixButton-con" title="Your server prefix">
          <span class="button is-static prefixButton">{{guild.guildDB.data.prefix}}</span>
        </p>
        <b-input placeholder="Enter tag name here!" v-model="commandName" maxlength="30" minlength="3" expanded name="command-name" custom-class="input-default"></b-input>
      </b-field>
    </div>
    <div class="column is-centered is-half">
      <b-field label="Command Message" style="text-align: left">
        <b-input placeholder="Enter tag message here!" type="textarea" v-model="commandMessage" maxlength="300" minlength="2" expanded name="command-message" custom-class="input-default"></b-input>
      </b-field>
    </div>
    <div class="column is-centered is-half">
      <VueRecaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></VueRecaptcha>
      <b-button type="is-success" :loading="this.$parent.$parent.$parent.$parent.actionAdding" :disabled="!commandName || !commandMessage || this.$parent.$parent.$parent.$parent.actionAdding" @click='submit'>Submit</b-button>
    </div>
  </section>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: 'Tag',
    props: ['guildData', 'disabled'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    components: {
      VueRecaptcha
    },
    data () {
      return {
        commandName: null,
        commandMessage: null
      }
    },
    methods: {
      submit () {
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.$parent.$parent.$parent.$parent.actionAdding = true
        console.log(recaptchaToken, this)
        const snackbar = this.$buefy.snackbar.open({
          message: 'Adding Tag, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/action/add`, body: { type: 'tag', name: this.commandName.trim(), content: this.commandMessage.trim(), recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: `Tag added! Run <code style="background-color: var(--main-color-bg-2);">${this.guild.guildDB.data.prefix}${this.commandName.trim()}</code>`,
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$parent.$parent.$parent.$parent.getGuild(true)
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$refs.recaptcha.reset()
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.getGuild()
        })
      },
      onCaptchaExpired: function () {
        this.$parent.$parent.$parent.$parent.actionAdding = false
        this.$refs.recaptcha.reset()
      }
    }
  }
</script>

<style>
  .tabs a {
    color: white;
  }
  .tabs ul {
    border-bottom-color: darkgrey;
  }
  .tabs a:hover {
    border-bottom-color: #7957d5;
    color: #7957d5;
  }
</style>

<style scoped>
  /* .label {
    float: left;
  } */
  .prefixButton {
    background: var(--main-color-bg-2);
    color: red;
    border-color: var(--main-color-bg-3) !important;
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.25) !important;
    z-index: 1 !important;
  }
  .columns {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
</style>
