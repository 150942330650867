<template>
  <section id="sectionPageMain" class="section is-main-section">
    <div class="tile is-ancestor" :class="{'is-disabled': !loaded}">

      <div class="tile is-parent">
        <div class="card tile is-child has-shadow">
          <div class="card-content">
            <div class="level">
              <div class="level-item">
                <div class="is-widget-label">
                  <h3 class="subtitle is-spaced has-text-centered">Member Count</h3>
                  <h1 class="title has-text-centered">{{ this.abbrNumber(approximate_member_count) }} / {{ this.abbrNumber(max_members) }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="card tile is-child has-shadow">
          <div class="card-content">
            <div class="level">
              <div class="level-item">
                <div class="is-widget-label">
                  <h3 class="subtitle is-spaced has-text-centered">Access Status</h3>
                  <h1 class="title has-text-centered">{{ access_status }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="card tile is-child has-shadow">
          <div class="card-content">
            <div class="level">
              <div class="level-item">
                <div class="is-widget-label">
                  <h3 class="subtitle is-spaced has-text-centered">Role Count</h3>
                  <h1 class="title">
                    <div class="has-text-centered">{{ this.abbrNumber(role_count) }}</div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="card tile is-child has-shadow">
          <div class="card-content">
            <div class="level">
              <div class="level-item">
                <div class="is-widget-label">
                  <h3 class="subtitle is-spaced has-text-centered">Channel Count</h3>
                  <h1 class="title">
                    <div class="has-text-centered">{{ this.abbrNumber(channel_count) }}</div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'GuildTopInfoCards',
    props: {
      loaded: {
        type: Boolean,
        default: false
      },
      approximate_member_count: {
        type: Number,
        default: 0
      },
      max_members: {
        type: Number,
        default: 250000
      },
      access_status: {
        type: String,
        default: 'None'
      },
      role_count: {
        type: Number,
        default: 0
      },
      channel_count: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
      }
    },
    methods: {
      abbrNumber: n => {
        if (n < 1e3) return n
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
      }
    }
  }
</script>

<style lang="scss" scoped>
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.is-widget-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<style lang="scss">
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    .tile.is-parent:nth-child(2) {
      display: none !important;
    }
  }
</style>
