<template>
  <b-sidebar position="static" fullheight mobile="reduce" open>
    <div class="p-1 Nunito">
      <div class="block" v-if="!loaded">
        <b-skeleton position="is-centered" circle width="80px" height="80px"></b-skeleton>
      </div>
      <div title="Click to reload data!" class="block" id="divarea" v-if="loaded && icon" @click="$parent.getGuild(true)" style="cursor: pointer;margin-bottom:10px;">
        <img class="icon-img has-shadow" v-if="!icon.includes('a_')" :class="{'static': icon.includes('a_')}" :src="this.$store.getters.admin.discord.cdn + 'icons/' + id + '/' + icon + '.webp?size=80'" :alt="name">
        <img class="icon-img has-shadow" v-else :src="this.$store.getters.admin.discord.cdn + 'icons/' + id + '/' + icon + '.gif?size=80'" :alt="name">
        <p style="margin-bottom: 0;" class="sidebar-guild-title title is-5 has-text-centered">{{ name }}</p>
      </div>
      <div title="Click to reload data!" class="block" v-else-if="loaded && name" id="divarea" @click="$parent.getGuild(true)" style="cursor: pointer;margin-bottom:10px;">
        <div class="icon-img-empty has-shadow Nunito" id="adwad5t">
          {{ name.replace(/'s /g, ' ').replace(/\w+/g, e => e[0]).replace(/\s/g, '') }}
        </div>
        <p style="margin-bottom: 0;" class="sidebar-guild-title title is-5 has-text-centered">{{ name }}</p>
      </div>
      <b-menu class="is-custom-mobile">
        <b-menu-list label="">
          <b-menu-item :disabled="!loaded" icon="users" :active="current === 'main' || current === 'customize'" :expanded="current === 'main' || current === 'customize'">
            <template slot="label" slot-scope="props">
              <span>
                {{$t('dashboard.sidebar.interactionsettings')}}
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                <b-tag class="is-pulled-right" style="line-height: 25px; margin-right: 10px; margin-top: -2px;" type="is-info">Updated</b-tag>
              </span>
            </template>
            <b-menu-item :disabled="!loaded" :active="current === 'main'" icon="home" tag="router-link" :to="{ path: '/dashboard/' + id }">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.main')}}
                  <b-tag class="is-pulled-right" type="is-info">Updated</b-tag>
                </span>
              </template>
            </b-menu-item>
            <b-menu-item :disabled="!loaded" :active="current === 'customize'" style="width: 100% !important;" icon="plus" tag="router-link" :to="{ path: '/dashboard/' + id + '/customize' }">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.customize')}}
                  <!-- <b-tag class="is-pulled-right" type="is-danger">NEW</b-tag> -->
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>

          <b-menu-item :disabled="!loaded" icon="hammer" :active="current === 'mod' || current === 'log' || current === 'audit'" :expanded="current === 'mod' || current === 'log' || current === 'audit'">
            <template slot="label" slot-scope="props">
              <span>
                {{$t('dashboard.sidebar.manage')}}
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                <!-- <b-tag v-if="beta" class="is-pulled-right" style="line-height: 25px; margin-right: 10px; margin-top: -2px;" type="is-info">Updated</b-tag> -->
              </span>
            </template>
            <b-menu-item :disabled="!loaded" icon="gavel" :active="current === 'mod'" tag="router-link" :to="{ path: '/dashboard/' + id + '/mod'}">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.mod')}}
                  <!-- <b-tag class="is-pulled-right" type="is-info">Updated</b-tag> -->
                </span>
              </template>
            </b-menu-item>
            <b-menu-item :disabled="!loaded" :active="current === 'log'" style="width: 100% !important;" icon="pen" tag="router-link" :to="{ path: '/dashboard/' + id + '/log' }">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.log')}}
                  <!-- <b-tag class="is-pulled-right" type="is-info">Updated</b-tag> -->
                </span>
              </template>
            </b-menu-item>
            <b-menu-item :disabled="!loaded" :active="current === 'audit'" style="width: 100% !important;" icon="book-open" tag="router-link" :to="{ path: '/dashboard/' + id + '/audit' }">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.audit')}}
                  <b-tag class="is-pulled-right" type="is-danger">Public Beta</b-tag>
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>

          <b-menu-item :disabled="!loaded" icon="hat-wizard" :active="current === 'level'" tag="router-link" :to="{ path: '/dashboard/' + id + '/level' }">
            <template slot="label">
              <span>
                {{$t('dashboard.sidebar.level')}}
              </span>
            </template>
          </b-menu-item>
          <b-tooltip type="is-danger" label="Coming Soon!" style="width: 100% !important;">
            <b-menu-item :disabled="true" :active="current === 'starboard'" icon-pack="fas" style="width: 100% !important;" icon="star" tag="router-link" :to="{ path: '/dashboard/' + id + '/starboard' }" label="Starboard"></b-menu-item>
          </b-tooltip>
          <b-menu-item :disabled="!loaded" icon="bell" :active="current === 'twitch' || current === 'youtube' || current === 'reddit'" :expanded="current === 'twitch' || current === 'youtube' || current === 'reddit'">
            <template slot="label" slot-scope="props">
              <span>
                {{$t('dashboard.sidebar.notifications')}}
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                <!-- <b-tag class="is-pulled-right" style="line-height: 25px; margin-right: 10px; margin-top: -2px;" type="is-info">Updated</b-tag> -->
              </span>
            </template>
            <b-menu-item :disabled="!loaded" icon-pack="fab" :active="current === 'twitch'" icon="twitch" tag="router-link" :to="{ path: '/dashboard/' + id + '/twitch' }" :label="this.$t('dashboard.sidebar.twitch')"></b-menu-item>
            <b-menu-item :disabled="!loaded" icon-pack="fab" :active="current === 'reddit'" icon="reddit" tag="router-link" :to="{ path: '/dashboard/' + id + '/reddit' }">
              <template slot="label">
                <span>
                  {{$t('dashboard.sidebar.reddit')}}
                  <!-- <b-tag class="is-pulled-right" type="is-danger">N</b-tag> -->
                </span>
              </template>
            </b-menu-item>
            <b-tooltip type="is-danger" label="Coming Soon!" style="width: 100% !important;">
              <b-menu-item :disabled="true" :active="current === 'youtube'" icon-pack="fab" style="width: 100% !important;" icon="youtube" tag="router-link" :to="{ path: '/dashboard/' + id + '/youtube' }" :label="this.$t('dashboard.sidebar.youtube')"></b-menu-item>
            </b-tooltip>
          </b-menu-item>
          <b-menu-item :disabled="!loaded" icon="music">
            <template slot="label" slot-scope="props">
              <span>
                {{$t('dashboard.sidebar.music')}}
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
              </span>
            </template>
            <b-tooltip type="is-danger" label="Coming Soon!" style="width: 100% !important;">
              <b-menu-item :disabled="true" :active="current === 'music'" icon-pack="fas" style="width: 100% !important;" icon="play" tag="router-link" :to="{ path: '/dashboard/' + id + '/music' }" :label="this.$t('dashboard.sidebar.musicSettings')"></b-menu-item>
            </b-tooltip>
            <b-menu-item :disabled="!loaded" icon-pack="fas" style="width: 100% !important;" icon="list" tag="router-link" :to="{ path: '/queue/' + id + '' }" :label="this.$t('dashboard.sidebar.queue')"></b-menu-item>
          </b-menu-item>
          <b-menu-item :disabled="!loaded" icon="ruler" :active="current === 'actions'" tag="router-link" :to="{ path: '/dashboard/' + id + '/actions' }" >
            <template slot="label">
              <span>
                {{$t('dashboard.sidebar.customActions')}}
                <!-- <b-tag class="is-pulled-right" type="is-info">Updated</b-tag> -->
              </span>
            </template>
          </b-menu-item>
          <b-menu-item :disabled="!loaded" icon="coins" tag="router-link" :to="{ path: `/leaderboard/${id}`}">
            <template slot="label">
              <span>
                {{$t('dashboard.sidebar.leaderboard')}}
              </span>
            </template>
          </b-menu-item>
          <b-menu-item :disabled="!loaded" v-if="!premium" tag="router-link" :to="{ path: '/premium', query: {guild_id: id} }" class="premium" :label="this.$t('dashboard.sidebar.premium.no')" icon="gift"></b-menu-item>
          <b-tooltip v-else type="is-info" label="Jk! Or else if u want :/" style="width: 100% !important;">
            <b-menu-item :disabled="!loaded" tag="router-link" :to="{ path: '/premium', query: {guild_id: id} }" class="premium" :label="this.$t('dashboard.sidebar.premium.yes')" icon="smile-wink" style="width: 100% !important;"></b-menu-item>
          </b-tooltip>
          <b-menu-item icon-pack="fas" icon="arrow-left" tag="router-link" :to="{ path: '/dashboard', query: {from_guild_id: id} }" :label="this.$t('dashboard.sidebar.goBack')" class="goback"></b-menu-item>
        </b-menu-list>
        <!-- <b-tooltip :v-if="Boolean(showAds && loaded)" type="is-info"> -->
          <Adsense tilte="Why do we show ads?. The simple answer is to make some money to keep us going. We made sure to place them in a way so your expirence damaged. If you dont want to see ads support us by getting premium!" v-if="Boolean(showAds && loaded) && !this.$window767.matches" ins-style="display:block;margin-top: 15px;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.dashboardSideBar" data-ad-format="auto"></Adsense>
        <!-- </b-tooltip> -->
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
  export default {
    name: 'GuildSidebar',
    props: {
      current: {
        required: true,
        default: 'main'
      },
      id: {
        type: [String, Number],
        default: ''
      },
      premium: {
        type: Boolean,
        default: false
      },
      showAds: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: null
      },
      loaded: {
        type: Boolean,
        default: false
      },
      beta: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style>
  .menu-list li ul {
    border-left: 1px solid var(--main-color-bg-3) !important;
  }
  .menu-label {
    color: lightgrey;
  }
  .sidebar-content.is-static.is-fullheight {
    background-color: var(--main-color-bg) !important;
    color: white !important;
    /* box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important; */
    box-shadow: 0.5rem -1.95rem 2.1rem rgba(0,0,0,.3)!important;
  }
  .menu-list a {
    color: white !important;
  }
  .menu-list .premium a, .menu-list .premium a:hover {
    color: gold !important;
  }
  .menu-list .goback a, .menu-list .goback a:hover {
    color: #9656eb !important;
    font-weight: bold !important;
  }
  .menu-list a.is-active {
    background-color: #7957d5 !important;
  }
  .menu-list a.is-active:hover {
    background-color: var(--main-color-bg-2) !important;
  }
  .menu-list a:hover {
    background-color: var(--main-color-bg-1) !important;
    color: white !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss">
  @media screen and (max-width: 768px) {
    .menu-list a > span:nth-child(2) {
      display: none !important;
    }
    .menu-list a > span:nth-child(1) {
      padding: 50% !important;
      padding-top: 0% !important;
      padding-bottom: 0% !important;
    }
    .icon-img {
      margin-top: 10px;
      height: 50px;
    }
    .icon-img-empty {
      margin-top: 10px !important;
      height: 52px !important;
      width: 52px !important;
      line-height: 48px !important;
    }
    // #gfe4208 {
    //   visibility: hidden !important;
    // }
    // #gfe4208CARD {
    //   visibility: visible !important;
    // }
  }
</style>

<style lang="scss" scoped>
  span.icon.is-pulled-right {
    display: unset !important;
  }
@media only screen and (max-width: 768px) {
  .b-skeleton-item.is-rounded {
    height:40px !important;
    width:40px !important;
  }
  .sidebar-guild-title {
    display: none !important;
  }
}
// @media only screen and (min-width: 768px) {
//   .b-sidebar .sidebar-content.is-mini-mobile:not(.is-mini-expand) .menu-list li a span:nth-child(2), .b-sidebar .sidebar-content.is-mini-mobile.is-mini-expand:not(:hover) .menu-list li a span:nth-child(2) {
//     display: unset !important;
//   }
// }
.block {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.icon-img {
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  background-color: var(--main-color-bg) !important;
}
.icon-img-empty {
  height: 84px;
  width: 84px;
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  line-height: 80px;
  background-color: var(--main-color-bg) !important;
  margin: auto;
  margin-bottom: 2px;
  margin-top: 2px;
}
.b-skeleton-item.is-rounded {
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  background-color: var(--main-color-bg) !important;
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
}
.sidebar-guild-title {
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  color: white;
}
.icon {
  color: white !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
@media screen and (max-width: 1023px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini-mobile {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
