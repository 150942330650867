
<template>
  <div class="columns is-centered" style="padding-top: 0.5rem !important;">
    <div class="column is-centered" style="">
      <div class="container has-shadow" :style="alertStyle">
        <b-notification has-icon icon="id-card" style="margin-left: 2rem; margin-right: 2rem;margin-top: -20px;" v-model="showNsfwAlert" type="is-warning" aria-close-label="Close notification" role="alert">
          Because r/{{ this.redditSelected ? new RegExp('^(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(?:reddit.com\/)?(?:\/)?r\/(.*?)?\/?$').exec(this.redditSelected)[1] : '???' }} is marked as Over18 (nsfw) we require the channel to be marked as nsfw also! Please try again but choose an nsfw channel!
        </b-notification>
        <div class="columns is-multiline">
          <!-- <div class="column action-column is-full"> -->
            <div class="column is-centered is-half">
              <b-field label="Mention Role (leave empty for no tag)">
                <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a role to be tagged!" :disabled="this.$parent.redditAdding" :calculatePosition="vselectfixer" autocomplete :searchable="true" :clearable="true" :reduce="item => (item.name.includes('everyone') ? 'everyone' : item.id)" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false) : [])" v-model="mentionRole" :getOptionLabel="option => option.name" label="Mention Role">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-field>
            </div>
            <div class="column is-centered is-half">
              <b-field label="Announcement Channel">
                <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a channel to send the message!" :disabled="this.$parent.redditAdding" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" v-model="mentionChannel" :getOptionLabel="option => option.name" label="Mention Channel">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-field>
            </div>
            <div class="column is-centered is-half">
              <b-field label="Subreddit">
                <v-select appendToBody :selectOnTab="true" placeholder="Type Subreddit name or enter subreddit url!" :calculatePosition="vselectfixer" :disabled="redditDisabled || this.$parent.redditAdding" autocomplete @search="onSearch" :filterable="false" :searchable="true" required :clearable="true" :reduce="item => item.url" :options="redditOptions" v-model="redditSelected" :getOptionLabel="option => option.display_name" label="Subreddit">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <img class="ta-img" :title="option.display_name" :src='option.icon_img || "http://cdn.gracebot.net/reddit/logo.png"'/>
                        {{ option.display_name }}
                        <b-tag class="is-pulled-right" v-if="option.over18" type="is-danger" style="margin-left: 10px;" size="is-small">NSFW</b-tag>
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center-s">
                      <img class="ta-img-s" title="option.display_name" :src='option.icon_img || "http://cdn.gracebot.net/reddit/logo.png"'/>
                      {{ option.display_name }}
                        <b-tag class="is-pulled-right" v-if="option.over18" type="is-danger" style="margin-left: 10px;" size="is-small">NSFW</b-tag>
                    </div>
                  </template>
                </v-select>
              </b-field>
            </div>
          <!-- </div> -->
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-centered" style="padding: 1.5rem;">
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></vue-recaptcha>
            <div class="buttons" style="margin-bottom: unset !important;">
              <b-button type="is-success" :loading="this.$parent.redditAdding" :disabled="!mentionChannel || !redditSelected || this.$parent.redditAdding" @click='submit'>Submit</b-button>
              <b-button type="is-info" :loading="this.$parent.redditAdding" @click='() => { this.$parent.showRedditAdd = false }'>Cancel</b-button>
            </div>
            <p style="font-size: 15px" v-if="this.mentionRole">Each post will get tagged.</p>
            <p style="font-size: 15px">Requires <code style="background-color: var(--main-color-bg-2);">SEND_MESSAGES, VIEW_CHANNEL, MANAGE_WEBHOOKS{{(this.mentionRole ? this.mentionRole : ' ').includes('everyone') ? ', MENTION_EVERYONE' : ''}}</code> Permissions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import { debounce } from 'underscore'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: 'addReddit',
    props: ['guildData', 'showRedditAdd'],
    computed: {
      guild: function () {
        return this.guildData
      },
      alertStyle () {
        if (this.showNsfwAlert) return 'background: var(--main-color-bg-2); border-radius: 10px;min-height: 200px; max-width: 100%;padding-top:40px;'
        else return 'background: var(--main-color-bg-2); border-radius: 10px;min-height: 200px; max-width: 100%;padding-top:20px;'
      }
    },
    components: {
      vSelect,
      VueRecaptcha
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        mentionChannel: null,
        mentionRole: null,
        redditOptions: [],
        redditSelected: null,
        redditDisabled: false,
        searchStringPrev: '',
        showNsfwAlert: false
      }
    },
    created () {
    },
    watch: {
      async 'mentionChannel' (mc) {
        this.checkOver18()
      },
      async 'redditSelected' (mc) {
        this.checkOver18()
      }
    },
    methods: {
      checkOver18 () {
        console.log(this.mentionChannel, this.redditSelected)
        if (!this.mentionChannel || !this.redditSelected) return
        console.log(this.redditOptions.find(g => g.url === this.redditSelected))
        if (this.redditOptions.find(g => g.url === this.redditSelected).over18 && !this.guild.bot.data.channels.find(g => g.id === this.mentionChannel).nsfw) {
          this.showNsfwAlert = true
          this.mentionChannel = null
        } else {
          this.showNsfwAlert = false
        }
      },
      onSearch (search, loading) {
        if (!search || search.length <= 0 || this.searchStringPrev === search.trim()) return
        this.searchStringPrev = search.trim()
        this.search(loading, search, this)
        // console.log(search, loading)
      },
      search: debounce((loading, search, vm) => {
        loading(true)
        vm.$store.dispatch('getApi', { path: 'plugin/reddit/lookup', params: { search: search.trim(), id: vm.$route.params.id } }).then(async (result) => {
          console.log(result.data, vm.redditOptions, '1')
          vm.redditOptions = result.data.results
          console.log(result.data, vm.redditOptions, '2')
          loading(false)
        }).catch(async () => {
          vm.redditOptions = []
          loading(false)
        })
      }, 350),
      submit () {
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.$parent.redditAdding = true
        const snackbar = this.$buefy.snackbar.open({
          message: 'Adding Subreddit, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        // eslint-disable-next-line
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/reddit/add`, body: { subreddit: new RegExp('^(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(?:reddit.com\/)?(?:\/)?r\/(.*?)?\/?$').exec(this.redditSelected)[1], role: this.mentionRole, channel: this.mentionChannel, recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Subreddit added!<br>Checks happen every 5min!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$parent.redditAdding = false
          this.$parent.showRedditAdd = false
          this.$parent.getGuild(true)
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          if ((error.response.data ? error.response.data.error : ' ').includes('Subreddit is over18')) {
            this.showNsfwAlert = true
            this.mentionChannel = null
            this.$buefy.snackbar.open({
              message: 'Channel is required to be nsfw as subreddit is!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          } else {
            this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
          this.$parent.redditAdding = false
          this.$refs.recaptcha.reset()
          await this.$store.dispatch('sleep', 2000)
          this.$parent.getGuild()
        })
      },
      onCaptchaExpired: function () {
        this.$parent.redditAdding = false
        this.$refs.recaptcha.reset()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      }
    }
  }
</script>

<style>
</style>

<style scoped>
  label {
    text-align: left !important;
  }
  .column.is-centered.is-half {
    padding: 1.5rem;
  }
  .columns {
    margin-left: 0.6rem !important;
    margin-right: 0.6rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }

.ta-img {
  height: auto;
  max-width: 3.5rem;
  margin-right: 1rem;
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
}

.d-center {
  display: flex;
  align-items: center;
  color: white;
}

.d-center-s {
  display: flex;
  align-items: center;
  /* color: white; */
}

.ta-img-s {
  width: auto;
  max-height: 32px;
  margin-right: 0.5rem;
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
}
</style>
