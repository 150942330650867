
<template>
  <div class="columns is-centered" style="padding-top: 0.5rem !important;">
    <div class="column is-centered" style="">
      <div class="container">
        <div class="columns is-mobile is-centered is-multiline">
          <div class="column action-column is-full has-shadow" style="background: var(--main-color-bg-2); border-radius: 10px;min-height: 200px;">
            <b-tabs :class="{'is-disabled': this.$parent.actionAdding}" animated position="is-centered">
              <b-tab-item icon-pack="fas" label="Command Tag" icon="sticky-note">
                <!-- Requires commandName, Tag Message | allows %user:ping% %user:text% %user:id% %guild:name% %guild:id% %newline% -->
                <Tag :guildData="guild ? guild : null" />
              </b-tab-item>
              <b-tab-item icon-pack="fas" label="Auto Message" icon="clock">
                <AMessage v-if="(guild ? (guild.guildDB.data.beta) : false)" :guildData="guild ? guild : null" />
              </b-tab-item>
              <b-tab-item icon-pack="fas" label="Command Role" icon="user-tag">
                <CRole :guildData="guild ? guild : null" />
              </b-tab-item>
              <b-tab-item icon-pack="fas" label="Reaction Role" icon="star">
                <b-notification :active="true" type="is-warning" aria-close-label="Close notification" role="alert">
                    Please use <code style="background-color: var(--main-color-bg-2);">{{guild.guildDB.data.prefix}}mroles</code> as we remake this part of the bot!
                </b-notification>
                <!-- <RRole :class="{'is-disabled': true}" :guildData="guild ? guild : null" /> -->
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Tag from '@/components/actions/tag.vue'
  import CRole from '@/components/actions/crole.vue'
  import AMessage from '@/components/actions/automessage.vue'
  // import RRole from '@/components/actions/rrole.vue'

  export default {
    name: 'addAction',
    props: ['guildData', 'showActionAdd'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    components: {
      Tag,
      CRole,
      AMessage
    },
    data () {
      return {
      }
    }
  }
</script>

<style>
  .tabs a {
    color: white;
  }
  .tabs ul {
    border-bottom-color: darkgrey;
  }
  .tabs a:hover {
    border-bottom-color: #7957d5;
    color: #7957d5;
  }
</style>

<style scoped>
  .columns {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
</style>
