
<template>
  <div class="columns is-centered" style="padding-top: 0.5rem !important;">
    <div class="column is-centered" style="">
      <div class="container has-shadow" style="background: var(--main-color-bg-2); border-radius: 10px;min-height: 200px; max-width: 100%;">
        <div class="columns is-multiline">
          <!-- <div class="column action-column is-full"> -->
            <div class="column is-centered is-half">
              <b-field label="Mention Role">
                <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a role to be tagged!" :disabled="this.$parent.twitchAdding" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="true" :reduce="item => (item.name.includes('everyone') ? 'everyone' : item.id)" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false) : [])" v-model="mentionRole" :getOptionLabel="option => option.name" label="Mention Role">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-field>
            </div>
            <div class="column is-centered is-half">
              <b-field label="Announcement Channel">
                <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a channel to send the message!" :disabled="this.$parent.twitchAdding" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.channels ? guild.bot.data.channels.filter(g => Number(g.type) === 0) : [])" v-model="mentionChannel" :getOptionLabel="option => option.name" label="Mention Channel">
                  <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                      <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                        <span class="Nunito" :style="`color: white;`">#{{ option.name }}</span> <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
                      </div>
                    </b-tooltip>
                  </template>
                </v-select>
              </b-field>
            </div>
            <div class="column is-centered is-half">
              <b-field label="Streamer">
                <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a streamer by typing!" :calculatePosition="vselectfixer" :disabled="twitchDisabled || this.$parent.twitchAdding" autocomplete @search="onSearch" :filterable="false" :searchable="true" required :clearable="true" :reduce="item => item._id" :options="twitchOptions" v-model="twitchSelected" :getOptionLabel="option => option.display_name" label="Twitch Streamer">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <img class="ta-img" :title="option.display_name" :src='option.logo'/>
                        {{ option.display_name }}
                        <b-icon title="Twitch Partner" v-if="option.broadcaster_type === 'partner'" style="padding-left: 10px; color: var(--twitch-partner-color);" pack="fas" icon="check" size="is-small" />
                        <b-icon title="Twitch Affiliate" v-else-if="option.broadcaster_type === 'affiliate'" style="padding-left: 10px; color: var(--twitch-partner-color);" pack="fas" icon="compass" size="is-small" />
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center-s">
                      <img class="ta-img-s" title="option.display_name" :src='option.logo'/>
                      {{ option.display_name }}
                        <b-icon title="Twitch Partner" v-if="option.broadcaster_type === 'partner'" style="padding-left: 10px; color: var(--twitch-partner-color);" pack="fas" icon="check" size="is-small" />
                        <b-icon title="Twitch Affiliate" v-else-if="option.broadcaster_type === 'affiliate'" style="padding-left: 10px; color: var(--twitch-partner-color);" pack="fas" icon="compass" size="is-small" />
                    </div>
                  </template>
                </v-select>
              </b-field>
            </div>
            <div class="column">
              <label class="label">Extra Settings's</label>
              <b-tooltip label="Instead of being sent by Grace it will show up as a Webhook!">
                <b-switch v-model="sendViaWebhook" type="is-info">Send by Webhook</b-switch>
              </b-tooltip>
            </div>
          <!-- </div> -->
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-centered" style="padding: 1.5rem;">
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></vue-recaptcha>
            <div class="buttons" style="margin-bottom: unset !important;">
              <b-button type="is-success" :loading="this.$parent.twitchAdding" :disabled="!mentionChannel || !mentionRole || !twitchSelected || this.$parent.twitchAdding" @click='submit'>Submit</b-button>
              <b-button type="is-info" :loading="this.$parent.twitchAdding" @click='() => { this.$parent.showTwitchAdd = false }'>Cancel</b-button>
            </div>
            <!-- MANAGE_WEBHOOKS -->
            <p style="font-size: 15px">Requires <code style="background-color: var(--main-color-bg-2);">SEND_MESSAGES, VIEW_CHANNEL{{this.sendViaWebhook ? ', MANAGE_WEBHOOKS' : ''}}{{(this.mentionRole ? this.mentionRole : ' ').includes('everyone') ? ', MENTION_EVERYONE' : ''}}</code> Permissions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import { debounce } from 'underscore'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: 'addTwitch',
    props: ['guildData', 'showTwitchAdd'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    components: {
      vSelect,
      VueRecaptcha
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        mentionChannel: null,
        mentionRole: null,
        twitchOptions: [],
        twitchSelected: null,
        twitchDisabled: false,
        searchStringPrev: '',
        sendViaWebhook: false
      }
    },
    created () {
    },
    methods: {
      onSearch (search, loading) {
        if (!search || search.length <= 0 || this.searchStringPrev === search.trim()) return
        this.searchStringPrev = search.trim()
        this.search(loading, search, this)
        // console.log(search, loading)
      },
      search: debounce((loading, search, vm) => {
        loading(true)
        vm.$store.dispatch('getApi', { path: 'plugin/twitch/lookup', params: { search: search.trim(), id: vm.$route.params.id } }).then(async (result) => {
          vm.twitchOptions = result.data.results
          loading(false)
        }).catch(async () => {
          vm.twitchOptions = []
          loading(false)
        })
      }, 350),
      submit () {
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.$parent.twitchAdding = true
        const snackbar = this.$buefy.snackbar.open({
          message: 'Adding Streamer, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/twitch/add`, body: { streamer: this.twitchSelected, role: this.mentionRole, channel: this.mentionChannel, webhook: this.sendViaWebhook, recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Streamer added!<br>Checks happen every 5mins!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$parent.twitchAdding = false
          this.$parent.getGuild(true)
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.$parent.twitchAdding = false
          this.$refs.recaptcha.reset()
          await this.$store.dispatch('sleep', 2000)
          this.$parent.getGuild()
        })
      },
      onCaptchaExpired: function () {
        this.$parent.twitchAdding = false
        this.$refs.recaptcha.reset()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      }
    }
  }
</script>

<style>
</style>

<style scoped>
  label {
    text-align: left !important;
  }
  .column.is-centered.is-half {
    padding: 1.5rem;
  }
  .columns {
    margin-left: 0.6rem !important;
    margin-right: 0.6rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }

.ta-img {
  height: auto;
  max-width: 3.5rem;
  margin-right: 1rem;
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
}

.d-center {
  display: flex;
  align-items: center;
  color: white;
}

.d-center-s {
  display: flex;
  align-items: center;
  /* color: white; */
}

.ta-img-s {
  width: auto;
  max-height: 32px;
  margin-right: 0.5rem;
  border-color: var(--main-color-bg-3);
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
}
</style>
