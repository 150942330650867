
<template>
  <section class="" style="">
    <!-- Requires commandName, Tag Message | allows %user:ping% %user:text% %user:id% %guild:name% %guild:id% %newline% -->
    <div class="column is-centered is-half">
      <b-field label="Command Name">
        <p class="control prefixButton-con" title="Your server prefix">
          <span class="button is-static prefixButton">{{guild.guildDB.data.prefix}}</span>
        </p>
        <b-input placeholder="Enter tag name here!" v-model="commandName" maxlength="30" minlength="3" expanded name="command-name" custom-class="input-default"></b-input>
      </b-field>
    </div>
    <div class="column is-centered is-half">
        <b-field label="Role">
            <v-select appendToBody :selectOnTab="true" placeholder="Search or Select a role!" :calculatePosition="vselectfixer" autocomplete :searchable="true" required :clearable="true" :reduce="item => item.id" :options="(guild.bot.data.roles ? guild.bot.data.roles.filter(g => g.managed === false && g.name !== '@everyone') : [])" v-model="addRole" :getOptionLabel="option => option.name" label="Role">
                <template slot="option" slot-scope="option" style="width: 100%">
                    <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                        <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                            <span class="Nunito" :style="`color: #${option.color.toString(16).padStart(6, '0')}`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.isAdmin" rounded type="is-danger">Admin</b-tag> <b-tag class="Nunito" v-if="option.isManager" rounded type="is-warning">Manage</b-tag>
                        </div>
                    </b-tooltip>
                </template>
            </v-select>
        </b-field>
    </div>
    <div class="column is-centered is-half">
      <VueRecaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></VueRecaptcha>
      <b-button type="is-success" :loading="this.$parent.$parent.$parent.$parent.actionAdding" :disabled="!commandName || !addRole || this.$parent.$parent.$parent.$parent.actionAdding" @click='submit'>Submit</b-button>
    </div>
  </section>
</template>

<script>
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: 'CommandRole',
    components: {
      // JsColor,
      vSelect,
      VueRecaptcha
    },
    props: ['guildData', 'disabled'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        commandName: null,
        addRole: null,
        SelectPlacement: 'bottom'
      }
    },
    methods: {
      submit () {
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.$parent.$parent.$parent.$parent.actionAdding = true
        console.log(recaptchaToken, this)
        const snackbar = this.$buefy.snackbar.open({
          message: 'Adding Command Role, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        this.$store.dispatch('postApi', { path: `guild/${this.$route.params.id}/action/add`, body: { type: 'crole', name: this.commandName.trim(), role: this.addRole, recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: `Command Role added! Run <code style="background-color: var(--main-color-bg-2);">${this.guild.guildDB.data.prefix}${this.commandName.trim()}</code>`,
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$parent.$parent.$parent.$parent.getGuild(true)
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.$parent.$parent.$parent.$parent.actionAdding = false
          this.$refs.recaptcha.reset()
          await this.$store.dispatch('sleep', 2000)
          this.$parent.$parent.$parent.$parent.getGuild()
        })
      },
      onCaptchaExpired: function () {
        this.$parent.$parent.$parent.$parent.actionAdding = false
        this.$refs.recaptcha.reset()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      }
    }
  }
</script>

<style>
  .tabs a {
    color: white;
  }
  .tabs ul {
    border-bottom-color: darkgrey;
  }
  .tabs a:hover {
    border-bottom-color: #7957d5;
    color: #7957d5;
  }
</style>

<style scoped>
  label {
    text-align: left !important;
  }
  .prefixButton {
    background: var(--main-color-bg-2);
    color: red;
    border-color: var(--main-color-bg-3) !important;
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.25) !important;
    z-index: 1;
  }
  .columns {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
</style>
